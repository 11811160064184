import React from "react";
import Layout from "../../components/layout/layout";
import Images from "../../components/productDetails/productImages";
import ProductPrices from "../../components/productDetails/productPrices";
import styles from "./productDetailPage.module.scss";
import ProductGroupCard from "../../components/product/productGroupCard";
import { Link } from "gatsby";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class productsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetail: null,
      isLoadingProducts: false,
      allProductsWithPrices: [],
    };
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    this.props.webshop.setProductDetailPrice(
      this.props.pageContext.product.productID
    );
    // Do this to get the updated prices for every similar product
    if (
      this.state.allProductsWithPrices.length === 0 &&
      !this.state.isLoadingProducts
    ) {
      this.setState({ isLoadingProducts: true });
      const self = this;
      fetch(
        process.env.GATSBY_ENV +
          "/api/v3/webshopProducts" +
          "?buyerGroupId=" +
          process.env.GATSBY_BUYER_GROUP
      )
        .then(response => response.json())
        .then(result => {
          let products = [];
          self.props.pageContext.allProducts.map(p => {
            result.map(pro => {
              if (parseInt(p.productID) === pro.productID) {
                let product = p;
                product.best_prices = pro.best_prices;
                // Do not add current product
                if (
                  pro.productID !==
                  parseInt(this.props.pageContext.product.productID)
                ) {
                  products.push(product);
                }
              }
            });
          });
          self.setState({
            isLoadingProducts: false,
            allProductsWithPrices: products,
          });
        });
    }
  }

  getTitle = () => {
    const title = this.props.pageContext.seoTitle || "%%PRODUCT%%";
    return title.replace(
      "%%PRODUCT%%",
      this.props.pageContext.product.productName
    );
  };

  getMetaDescription = () => {
    const description = this.props.pageContext.seoDescription || "%%PRODUCT%%";
    return description.replace(
      "%%PRODUCT%%",
      this.props.pageContext.product.productName
    );
  };

  getSimilarProducts = () => {
    return this.state.allProductsWithPrices;
  };

  render() {
    return (
      <Layout
        webshopfooterName={this.props.pageContext.webshopfooterName}
        webshopCity={this.props.pageContext.webshopCity}
        webshopAddress={this.props.pageContext.webshopAddress}
        webshopZipCode={this.props.pageContext.webshopZipCode}
        webshopOpeningHours={this.props.pageContext.webshopOpeningHours}
        footerLink={this.props.pageContext.webshopTermsLink}
        webshopName={this.props.pageContext.webshopName}
        logoUrl={this.props.pageContext.webshopLogoUrl}
        webshopPrivacyLink={this.props.webshopPrivacyLink}
        webshopCompanyInfoLink={this.props.webshopCompanyInfoLink}
        favicon={this.props.favicon}
        buyerGroupId={this.props.pageContext.buyerGroupId}
        webshopLogo={this.props.pageContext.webshopLogo}
        title={this.getTitle()}
        metaDescription={this.getMetaDescription()}
        headers={this.props.pageContext.headers}
        footers={this.props.pageContext.footers}
      >
        <div className={styles.productDetailContainer}>
          <div className={[styles.breadcrumbContainer]}>
            <Link to={"/altmetall-ankauf"} className={[styles.breadcrumbItem]}>
              Altmetallankauf
            </Link>
            >
            {this.props.pageContext.parentCategory === null ? null : (
              <>
                <Link
                  to={this.props.pageContext.categoryPath
                    .split("/")
                    .slice(0, -1)
                    .join("/")}
                  className={[
                    styles.breadcrumbItem,
                    styles.breadcrumbCategoryItem,
                    styles.breadcrumbSubCategoryItem,
                  ].join(" ")}
                >
                  {this.props.pageContext.parentCategory.name}
                </Link>
                >
              </>
            )}
            <Link
              to={this.props.pageContext.categoryPath}
              className={[
                styles.breadcrumbItem,
                styles.breadcrumbCategoryItem,
              ].join(" ")}
            >
              {this.props.pageContext.categoryName}
            </Link>
            >
            <span className={[styles.breadcrumbActive]}>
              {this.props.pageContext.product.productName}
            </span>
          </div>
          <div className={styles.productDetailPage}>
            <div className={styles.titleWrapper}>
              <h1
                className={[styles.pageTitle, "blackColor", "pageTitle"].join(
                  " "
                )}
              >
                {this.props.pageContext.product.productName}{" "}
                {this.props.pageContext.webshopName}{" "}
                {this.props.pageContext.webshopLocationName}{" "}
              </h1>
            </div>
            <div className={styles.firstSection}>
              <Images
                images={this.props.pageContext.product.images}
                slug={this.props.pageContext.product.slug}
              />
              {this.props.webshop &&
              this.props.webshop.productPrices &&
              this.props.webshop.productPrices[
                this.props.pageContext.product.productID
              ] ? (
                <ProductPrices
                  buyerId={this.props.pageContext.buyerId}
                  best_prices={
                    this.props.webshop.productPrices[
                      this.props.pageContext.product.productID
                    ]
                  }
                  slug={this.props.pageContext.product.slug}
                  productID={this.props.pageContext.product.productID}
                  currencySymbol={this.props.pageContext.currencySymbol}
                />
              ) : (
                <CircularProgress className={styles.loading} />
              )}
            </div>

            <div className={styles.productDescriptionWrapper}>
              <h2
                className={["pageSubTitle", styles.descriptionTitle].join(" ")}
              >
                {" "}
                Produktbeschreibung zu{" "}
                {this.props.pageContext.product.productName}{" "}
                {this.props.pageContext.webshopLocationName}
              </h2>

              <div
                key={`body`}
                id="___gatsby"
                className={[styles.productDescription, "blackColor"].join(" ")}
                dangerouslySetInnerHTML={{
                  __html: this.props.pageContext.product.description,
                }}
              />
            </div>
            <div>
              <h3 className="pageTitle blackColor">Ähnliche Produkte </h3>
              <ProductGroupCard
                buyerId={this.props.pageContext.buyerId}
                allProducts={this.getSimilarProducts()}
                productDetailPage={true}
                currencySymbol={this.props.pageContext.currencySymbol}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
