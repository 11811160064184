import React from "react";
import styles from "./productCard.module.scss";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Carousel } from "react-responsive-carousel";
import { Link } from "gatsby";
import { Location } from "@reach/router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.scss";

const InfoPrice = props => (
  <div className={styles.groupInfoPrice}>
    <span className={styles.pricePerKg}> {(Math.floor(props.price * 100) / 100).toFixed(2) } </span>
    <span className={styles.weightInKg}>ab {props.weight} kg</span>
  </div>
);

class CardProduct extends React.Component {
  state = {
    total: "0.00",
    disableSubmit: true,
    redirectPro: false,
    url: "",
  };

  onSubmit = event => {
    event.preventDefault();
    window.location.replace(this.state.url);
  };

  setSubmitUrl = weight => {
    var url =
      "/co/cart/g/" +
      this.props.buyerGroupId +
      "/" +
      this.props.productID +
      "?quantity=" +
      weight;

    this.setState({ url: url });
  };

  onFocus = event => {
    this.props.setActive(this.props.productID);
  };
  handleInputChange = event => {
    const target = event.target;
    var value = parseFloat(target.value);
    var priceToCalculate = 0;
    var redirectPro = false;
    if (!isNaN(value) && parseInt(this.props.best_prices[0].start) <= value) {
      priceToCalculate = this.props.best_prices[0].price;
      for (var i = 0; this.props.best_prices.length > i; i++) {
        if (parseInt(this.props.best_prices[i].start) > value) {
          break;
        } else {
          priceToCalculate = this.props.best_prices[i].price;
        }
        if (i > 2) {
          redirectPro = true;
        } else {
          redirectPro = false;
        }
      }
      this.setState({ disableSubmit: false });
    } else {
      value = 0;
      this.setState({ disableSubmit: true });
      redirectPro = false;
    }

    let total = (value * priceToCalculate).toFixed(2);
    if (total >= 3500) {
      redirectPro = true;
    }

    this.setState({ total: total });
    this.setState({ redirectPro: redirectPro }, () =>
      this.setSubmitUrl(target.value)
    );
  };

  getProductLocation = (locationProps, props) => {
    const parts = locationProps.location.pathname.replace("/s", "");
    const { productDetailPage, slug } = props;
    if (productDetailPage) {
      return (
        parts
          .split("/")
          .slice(0, parts.split("/").length - 1)
          .join("/") +
        "/" +
        slug
      );
    }
    return parts + "/" + slug;
  };

  render() {
    return (
      <Location>
        {locationProps => (
          <form method="get" className={styles.form} onSubmit={this.onSubmit}>
            <div
              className={
                this.props.active
                  ? [
                      styles.cardWrapper,
                      styles.active,
                      "cardWrapperActive",
                    ].join(" ")
                  : [styles.cardWrapper].join(" ")
              }
            >
              <div className={styles.titleGroup}>
                <h2 className={styles.cardTitle}>
                  {" "}
                  <Link
                    className="blackColor"
                    to={this.getProductLocation(locationProps, this.props)}
                  >
                    {" "}
                    {this.props.productName}
                  </Link>
                </h2>
              </div>

              <div
                className={[styles.carouselWrapper, "primaryColorDot"].join(
                  " "
                )}
              >
                <Carousel
                  showThumbs={false}
                  infiniteLoop={true}
                  width={"270px"}
                  dynamicHeight={true}
                  showStatus={false}
                >
                  {this.props.images.length ? (
                    this.props.images.map((val, index) => {
                      return (
                        <img
                          key={val._id}
                          alt="material"
                          className={styles.materialImage}
                          src={
                            "https://images.scrap24.com/" +
                            this.props.slug.split("-").join("_") +
                            "-270x180-" +
                            val._id +
                            ".jpg"
                          }
                        />
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </Carousel>
              </div>

              <div className={styles.priceGroup}>
                <span className={[styles.ankaufpreise, "blackColor"].join(" ")}>
                  Ankaufpreise in {this.props.currencySymbol}/kg
                </span>

                <div className={[styles.pricesGroup, "blackColor"].join(" ")}>
                  <InfoPrice
                    price={this.props.best_prices[0].price}
                    weight={this.props.best_prices[0].start}
                  />
                  <InfoPrice
                    price={this.props.best_prices[1].price}
                    weight={this.props.best_prices[1].start}
                  />
                  <InfoPrice
                    price={this.props.best_prices[2].price}
                    weight={this.props.best_prices[2].start}
                  />
                </div>

                <div
                  className={[styles.cardCalculation, "blackColor"].join(" ")}
                >
                  {this.props.currencySymbol} {this.state.total}
                </div>
                <div className={styles.inputGroup}>
                  <TextField
                    className={[
                      styles.inputAmount,
                      "inputAmount",
                      this.state.disableSubmit ? "disabledCard" : "enabledCard",
                    ].join(" ")}
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    id={"input" + this.props.slug}
                    autoComplete="off"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    placeholder="Gewicht schätzen"
                  />

                  <span
                    className={[styles.inputUnit, "inputUnitSpan"].join(" ")}
                  >
                    {" "}
                    kg{" "}
                  </span>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={[styles.buyButton, "productBuyButton"].join(" ")}
                    disabled={this.state.disableSubmit}
                  >
                    {this.state.redirectPro
                      ? "GROSSKUNDE"
                      : "IN DEN VERKAUFSKORB"}
                  </Button>

                  <Link to={this.getProductLocation(locationProps, this.props)}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={[styles.moreInfoButton].join(" ")}
                    >
                      MEHR INFO
                    </Button>
                    <span className={styles.moreInfoButtonMobile}>
                      Mehr info
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </form>
        )}
      </Location>
    );
  }
}

export default CardProduct;
