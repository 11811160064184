import React from "react"
import styles from "./productImages.module.scss"



export default class IndexPage extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            main_img: this.props.images[0],
            arr_img: this.props.images.slice(1, this.props.images.length)
        }

        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e){
        var temp = this.state.main_img;
        this.setState({main_img: this.state.arr_img[e.target.dataset.index] })
        var arr_temp = this.state.arr_img;
        arr_temp[e.target.dataset.index] = temp
        this.setState({arr_img: arr_temp })
    }
    render(){
        return (
            <div> 
                <img className={styles.bigImage } alt="big material" src={  "https://images.scrap24.com/" + this.props.slug.split('-').join('_') + "-450x324-" +   this.state.main_img._id + ".jpg"}       />
                <div className={styles.smallImagesWraper }>
                    {  this.state.arr_img.map((val, index) => {
                        return <img key = {index} alt="small material" data-index={index}  onClick={this.handleClick} src={"https://images.scrap24.com/" + this.props.slug.split('-').join('_') + "-65x40-" +  val._id + ".jpg"}/>
                    })
                    }
                </div>   
            </div>
            )   
        }
    }