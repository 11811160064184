import React from "react";
import styles from "./productPrices.module.scss";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
const inputTheme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: "rgba(76, 101, 131, 0.5)",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "8px",
        fontFamily: "Lato",
      },
    },
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: "100%",
        "@media (max-width: 960px)": {
          height: "52px",
        },
      },
    },
    MuiFormControl: {
      root: { width: "100%", height: "80px" },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          border: "1px solid",
          borderColor: "#ddd",
        },
        "&$focused $notchedOutline": {
          border: "1px solid",
          borderColor: "#ddd",
        },
      },
    },
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total: "0.00",
      disableSubmit: true,
      redirectPro: false,
      url: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.setSubmitUrl = this.setSubmitUrl.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onSubmit = event => {
    event.preventDefault();
    window.location.replace(this.state.url);
  };

  setSubmitUrl = weight => {
    var url =
      "/co/cart/g/" +
      this.props.buyerId +
      "/" +
      this.props.productID +
      "?quantity=" +
      weight;

    this.setState({ url: url });
  };

  handleInputChange = event => {
    const target = event.target;
    var value = parseFloat(target.value);
    var priceToCalculate = 0;
    var redirectPro = false;
    if (!isNaN(value) && parseInt(this.props.best_prices[0].start) <= value) {
      priceToCalculate = this.props.best_prices[0].price;
      for (var i = 0; this.props.best_prices.length > i; i++) {
        if (parseInt(this.props.best_prices[i].start) > value) {
          break;
        } else {
          priceToCalculate = this.props.best_prices[i].price;
        }
        if (i > 2) {
          redirectPro = true;
        } else {
          redirectPro = false;
        }
      }
      this.setState({ disableSubmit: false });
    } else {
      value = 0;
      this.setState({ disableSubmit: true });
      redirectPro = false;
    }

    let total = (value * priceToCalculate).toFixed(2);
    if (total >= 3500) {
      redirectPro = true;
    }

    this.setState({ total: total });
    this.setState({ redirectPro: redirectPro }, () =>
      this.setSubmitUrl(target.value)
    );
  };

  render() {
    return (
      <div className={[styles.productPrices, "blackColor"].join(" ")}>
        <div className={styles.pricesGroup}>
          <div className={styles.pricesHeader}>
            {" "}
            <span className={styles.priceText}>Ankaufpreise </span>{" "}
            <span className={styles.priceText}>
              {this.props.currencySymbol}/kg:{" "}
            </span>
          </div>
          <div className={styles.pricesWrapper}>
            {this.props.best_prices.slice(0, 3).map((val, index) => {
              return (
                <div key={index} className={styles.priceGroup}>
                  <span className={styles.price}>{(Math.floor(val.price * 100) / 100).toFixed(2) }</span>
                  <span className={styles.start}>ab {val.start} kg</span>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={`${styles.inputWrapper} ${
            this.state.disableSubmit ? styles.notActive : styles.active
          }`}
        >
          <div className={styles.formHeader}>
            <div className={styles.totalPrice}>
              {this.props.currencySymbol} {this.state.total}{" "}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <form className={styles.form} method="get" onSubmit={this.onSubmit}>
              <div className={styles.inputAmountWrapper}>
                <MuiThemeProvider theme={theme}>
                  <TextField
                    className={[styles.inputAmount, "inputAmount"].join(" ")}
                    onChange={this.handleInputChange}
                    id="outlined-bare"
                    autoComplete="off"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    placeholder="Gewicht schätzen"
                  />
                </MuiThemeProvider>
                <span className={styles.amountUnit}>kg</span>
              </div>

              <MuiThemeProvider theme={inputTheme}>
                <Button
                  type="submit"
                  disabled={this.state.disableSubmit}
                  variant="contained"
                  className={[styles.button, "productBuyButton"].join(" ")}
                >
                  {" "}
                  {this.state.redirectPro
                    ? "GROSSKUNDE"
                    : "IN DEN VERKAUFSKORB"}{" "}
                </Button>
              </MuiThemeProvider>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
