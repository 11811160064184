import React from "react";
import styles from "./productGroupCard.module.scss";
import CardProduct from "./productCard";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class ProductGroupCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
    };

    this.setActive = this.setActive.bind(this);
  }

  setActive(productId) {
    this.setState({ active: productId });
  }
  render() {
    return (
      <div className={styles.productPage}>
        <div className={styles.productWrapperContainer}>
          <div className={styles.productWrapper}>
            {this.props.allProducts && this.props.allProducts.length ? (
              this.props.allProducts
                .filter(value => value.best_prices.length)
                .map((value, index) => {
                  return (
                    <div className={styles.item}>
                      <CardProduct
                        setActive={this.setActive}
                        active={this.state.active === value.productID}
                        key={value.productID}
                        productDetailPage={this.props.productDetailPage}
                        buyerGroupId={this.props.buyerId}
                        images={value.images}
                        productID={value.productID}
                        productName={value.productName}
                        slug={value.slug}
                        best_prices={value.best_prices}
                        currencySymbol={this.props.currencySymbol}
                      />
                    </div>
                  );
                })
            ) : (
              <div>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
