import React from "react";

import ProductDetail from "./productDetail";

import WebshopContext from "../../providers/WebshopProvider";

export default ({ data, pageContext }) => (
  <WebshopContext.Consumer>
    {webshop => (
      <ProductDetail
      webshopCity ={pageContext.webshopCity}
      webshopAddress ={pageContext.webshopAddress}
      webshopZipCode ={pageContext.webshopZipCode}
      webshopOpeningHours ={pageContext.webshopOpeningHours}

        webshopPrivacyLink={pageContext.webshopPrivacyLink}
        webshopCompanyInfoLink={pageContext.webshopCompanyInfoLink}
        favicon={pageContext.favicon}
        footerLink={pageContext.webshopTermsLink}
        webshopName={pageContext.webshopName}
        logoUrl={pageContext.webshopLogoUrl}
        webshopFreeText={pageContext.webshopFreeText}
        webshopMap={pageContext.webshopMap}
        buyerId={pageContext.buyerGroupId}
        pageContext={pageContext}
        data={data}
        webshop={webshop}
        currencySymbol={pageContext.currencySymbol}
      />
    )}
  </WebshopContext.Consumer>
);
